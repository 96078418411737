body.catalog-category-view, body.ambrand-index-index{
    .product-item-info{
        .size-tag-container{
            width: calc(100% - 25px);
            background: transparent;
            color: #232323;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin: auto;
            padding: 10px 10px 0 10px;
            span.size-tag-title{
                display: block;
                width: 100%;
                text-align: center;
                font-size: 10px;
            }
            div{
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                width: 100%;
                a{
                    border: none;
                    span.size-tag{
                        display: block;
                        background: #f6f5f6;
                        color: #232323;
                        padding: 5px;
                        margin: 5px 5px 0 5px;
                        box-sizing: border-box;
                        border: 1px solid #dcdcdc;
                    }
                }
            }
        }
    }
}